import React from "react"
import Layout from "../components/layout"

const Credits = () => {
  return (
  <Layout>
      <h1 className="breadcrumb">Credits</h1>
      <div className="container">
        <h2>Site internet</h2>
        <p>Graphisme, intégration, programmation : <a href="https://oliviergenevest.info" title="Olivier Genevest" target="_blank" rel="noopener noreferrer">Olivier Genevest</a> - 07 82 13 95 23 </p>
        
        <h2 style={{'marginTop':'2rem'}}>Photos</h2>
        <p>Crédits : Cahuate Milk, David Bonnet </p>
      </div>
  </Layout>
  )
}

export default Credits